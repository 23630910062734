<template>
  <WidthWrapper
    type="article"
    class="mb-36"
    data-aller-id="content-small-teaser"
    :style="brand?.colors?.primary?.background && `--color-primary: ${brand?.colors?.primary?.background}`"
  >
    <component
      :is="teaserComponent"
      :to="convertToRelativeUrl(product?.url)"
      class="group block lg:flex"
      :class="{'overflow-hidden rounded-lg': product}"
      @click="trackClick()"
    >
      <div v-if="componentContent.media?.imageUrl" :class="[{'order-last': componentContent.direction === 'right'}, product ? 'lg:w-6/12' : 'lg:w-7/12']">
        <picture>
          <source v-if="!product" media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=590&height=359&format=webp 590w, ${componentContent.media?.imageUrl}?width=885&height=539&format=webp 885w`" sizes="740px">
          <source v-if="product" media="(min-width:1140px)" :srcset="`${componentContent.media?.imageUrl}?width=486&height=359&format=webp 486w, ${componentContent.media?.imageUrl}?width=729&height=539&format=webp 729w`" sizes="48vw">
          <source v-if="product" media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=486&height=390&format=webp 486w, ${componentContent.media?.imageUrl}?width=729&height=585&format=webp 729w`" sizes="48vw">
          <img :src="`${componentContent.media?.imageUrl}?width=288&height=304&format=webp`" :srcset="`${componentContent.media?.imageUrl}?width=288&height=304&format=webp 288w, ${componentContent.media?.imageUrl}?width=576&height=608&format=webp 576w, ${componentContent.media?.imageUrl}?width=736&height=777&format=webp 736w, ${componentContent.media?.imageUrl}?width=864&height=912&format=webp 864w`" :alt="componentContent.media?.alt || componentContent.media?.imageUrl" width="288" height="304" sizes="(min-width:1024px) 740px, 100vw" :class="{'rounded-lg': !product}" loading="lazy">
        </picture>
      </div>
      <div class="relative flex flex-col justify-center px-16 py-20 lg:p-50" :class="{ 'justify-stretch overflow-hidden text-white lg:w-6/12': product }">
        <div v-if="product" class="absolute inset-0 -z-10">
          <picture>
            <source v-if="!product" media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=590&height=359&format=webp 590w, ${componentContent.media?.imageUrl}?width=885&height=539&format=webp 885w`" sizes="740px">
            <source v-if="product" media="(min-width:1140px)" :srcset="`${componentContent.media?.imageUrl}?width=486&height=359&format=webp 486w, ${componentContent.media?.imageUrl}?width=729&height=539&format=webp 729w`" sizes="48vw">
            <source v-if="product" media="(min-width:1024px)" :srcset="`${componentContent.media?.imageUrl}?width=486&height=390&format=webp 486w, ${componentContent.media?.imageUrl}?width=729&height=585&format=webp 729w`" sizes="48vw">
            <img :src="`${componentContent.media?.imageUrl}?width=288&height=304&format=webp`" :srcset="`${componentContent.media?.imageUrl}?width=288&height=304&format=webp 288w, ${componentContent.media?.imageUrl}?width=576&height=608&format=webp 576w, ${componentContent.media?.imageUrl}?width=736&height=777&format=webp 736w, ${componentContent.media?.imageUrl}?width=864&height=912&format=webp 864w`" :alt="componentContent.media?.alt || componentContent.media?.imageUrl" width="288" height="304" sizes="(min-width:1024px) 740px, 100vw" class="h-full w-full" loading="lazy">
          </picture>
          <div class="absolute inset-0 -m-1 bg-black/30 backdrop-blur-3xl" />
        </div>
        <div v-if="brand" class="mb-15 flex h-[50px] items-center justify-start opacity-50">
          <img v-if="brand.logo?.imageUrl" :src="brand.logo?.imageUrl" :alt="`${brand.title} logo`" class="h-full w-auto max-w-[80%] invert" width="77" height="50" loading="lazy">
        </div>
        <div class="max-w-[415px]" :class="{ 'flex grow flex-col items-start': product }">
          <div :class="{ 'flex grow flex-col justify-center': product }">
            <h2 v-if="componentContent.title" class="mb-10 text-24 font-semibold leading-140p md:mb-16 md:text-30">
              {{ componentContent.title }}
            </h2>
            <ContentWysiwyg
              :component-content="{ type: 'text', text:componentContent.description }"
            />
          </div>
          <div v-if="componentContent.cta && !product" class="text-16 mt-26 font-semibold leading-120p text-blue-400">
            <NuxtLink
              v-if="componentContent.cta.href"
              :to="componentContent.cta.href"
              class="flex items-center"
            >
              {{ componentContent.cta.text }}
              <LazySharedIcon
                name="chevron-right"
                size="size-24"
              />
            </NuxtLink>
            <div v-else>
              {{ componentContent.cta.text }}
            </div>
          </div>
          <div>
            <BubbleLabel
              v-if="product?.variants"
              class="mb-10"
            >
              Fra {{ findLowestProductVariantPrice(product.variants) }} kr.
            </BubbleLabel>
          </div>
          <Button
            v-if="product"
            class="w-full !border-transparent !bg-white/20 hover:!bg-blue-600 group-hover:!bg-blue-600 lg:w-auto"
          >
            {{ componentContent?.cta?.text ||'Køb abonnement' }}
            <LazySharedIcon
              name="chevron-right"
              size="size-24"
            />
          </Button>
        </div>
      </div>
    </component>
  </WidthWrapper>
</template>

<script setup lang="ts">
import { ProductWithVariantsTransformer, type ContentPageBrand, type SmallTeaserBlock } from '@aller/nuxt-allerservice-sdk/transformers';

const props = defineProps({
  componentContent: {
    type: Object as PropType<SmallTeaserBlock>,
    required: true,
  },
});

const product = props.componentContent.relatedProductGuid ? await useCommerce().products().id(props.componentContent.relatedProductGuid).then((data) => ProductWithVariantsTransformer(data.value)).catch(() => {
  console.error('Invalid product reference', props.componentContent.relatedProductGuid);
  return null;
}) : null;

const brands = useState<ContentPageBrand[]>('brands');
const brand = ref<ContentPageBrand | null>();
const teaserComponent = product ? resolveComponent('NuxtLink') : 'div';

// As the brands state will only be present on client we move the data setting to mounted.
onMounted(() => {
  brand.value = product ? brands?.value?.filter((brand) => brand.guid === product.brand?.guid)[0] : null;
});

const trackClick = () => {
  useGTM().pushElement('click_teaser_small', {
    section_id: `content-large-teaser-${props.componentContent.guid}`,
    section_text: undefined,
    element_id: product?.advantagePromotionCode || 'content-small-teaser',
    element_text: product ? 'Køb abonnement' : props.componentContent.cta?.text || '',
    element_link: product?.url || props.componentContent.cta?.href,
  });
};
</script>
